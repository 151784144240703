<template>
  <div class="py-6 w-full px-3">
    <h1 class="text-lg text-left font-bold flex mb-0 -mt-2">
      Audit Log database storage
    </h1>
    <p class="text-jet my-5">
      Your audit log provides historical logs of actions and events on your
      Cloudenly account. It can be useful in helping you to isolate user events
      and better manage identity and access permissions.
    </p>
    <div class="flex items-end w-1/2 gap-2 mb-4">
      <div class="w-1/2">
        <c-text
          placeholder="--Enter--"
          label="Retentiion Period"
          type="number"
          :rules="['required']"
          v-model="audit.retentionPeriod"
        />
      </div>
      <div class="w-1/2">
        <c-select
          placeholder="--Select--"
          :options="options"
          :rules="['required']"
          v-model="audit.month"
        />
      </div>
    </div>
    <div class="alert">
      Your logs will be stored in the database, limited to 1 million records at
      any given time, so regardless of the retention period set, only the last 1
      million records shall be stored.
    </div>
    <div>
      <h1 class="text-lg text-left font-bold text-darkPurple">
        Audit log Coverage
      </h1>
      <p class="mt-4 text-jet">
        Select the areas of your account to log events and actions
      </p>
      <divider border-dash class="my-5" />
      <h3 class="text-lg text-left font-bold">Coverage area</h3>
      <div class="bg-others mt-5 p-4 ">
        <div v-for="(coverage, index) in coverageArea" :key="index">
          <div class="flex justify-between">
            <h3 class="font-bold text-darkPurple">
              {{coverage.title}}
            </h3>
            <toggle-switch @input="coverage.value = $event" :value="coverage.value" />
          </div>
          <h5 class="mt-2">
            {{coverage.sub}}
          </h5>
          <divider border-dash class="mt-2 mb-6" />
        </div>
      </div>
    </div>
    <div class="flex">
      <Button
        class="bg-dynamicBackBtn text-white my-6 w-40 flex rounded"
        @click="handleSaveAuditSettings()"
        :disabled="disableBtn"
      >
        Save changes
      </Button>
      <Button class="text-darkPurple my-6 w-40 flex rounded">
        Cancel
      </Button>
    </div>
  </div>
</template>

<script>
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Divider from "@/components/divider";
import Button from "@/components/Button";
import ToggleSwitch from "@/components/Switch";

export default {
  name: "AuditSettings",
  components: {
    CSelect,
    CText,
    Divider,
    Button,
    ToggleSwitch
  },
  data() {
    return {
      options: ["Month"],
      disableBtn: true,
      audit: {
        retentionPeriod: null,
        month: null
      },
      coverageArea: [
        {
          title: "Configuration and administration",
          sub:
            "keeps a log of system admin or configuration actions such as account changes or updates to product settings",
          value: false
        },
        {
          title: "Security",
          sub:
            "keeps a log of users actions related to security such as login events, invalid login credentials, authentication, security setting updates, etc.",
          value: false
        },
        {
          title: "Privileges",
          sub:
            "keeps a log of identity & access realted configurations and events such as privilege updates.",
          value: false
        },
        {
          title: "Permissions",
          sub:
            "keeps a log of identity & access realted configurations and events such as permission updates.",
          value: false
        },
        {
          title: "User management",
          sub:
            "keeps a log of changes relating to users, groups, memberships and roles such as adding and removing users and groups.",
          value: false
        },
        {
          title: "End-user activity",
          sub:
            "keeps a log of user navigations and user actions such as create, edit, delete, import, export, executed searches, visited pages, etc.",
          value: false
        },
        {
          title: "Employee Timeline",
          sub:
            "keeps a log of milestones and major events that occur during the lifetime of each employee.",
          value: false
        },
        {
          title: "Apps",
          sub:
            "keeps a log of events generated by integrated 3rd party apps.",
          value: false
        },
      ]
    };
  },
  methods: {
    handleSaveAuditSettings() {}
  }
};
</script>

<style scoped>
.alert {
  display: flex;
  align-items: center;
  top: 2rem;
  right: 4rem;
  background: rgba(233, 147, 35, 0.12);
  margin-bottom: 20px;
  margin-top: 10px;
  border: 1px solid #e99323;
  border-radius: 4px;
  padding: 16px;
  font-size: 16px;
}
</style>
